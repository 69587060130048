import { Component } from '@angular/core';
import { GroupsService } from '../../service/groups.service';
import { StockService } from '../../service/stock.service';
import { DatesService } from '../../service/dates.service'
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { PopupDownloadComponent } from '../../components/modals/popup-download/popup-download.component';
import { CostCenterService } from '../../service/cost-center.service';
import { CMVService } from '../../service/cmv.service';
import { StockDetailComponent } from './stock-detail/stock-detail.component';
import { StockAdjustmentComponent } from './stock-adjustment/stock-adjustment.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'low-stock',
  templateUrl: './low-stock.component.html',
  styleUrls: ['./low-stock.component.css']
})

export class LowStockComponent {

  constructor(
    private datesService: DatesService,
    private groupsService: GroupsService,
    private stockService: StockService,
    private modalService: MDBModalService,
    private costCenterService: CostCenterService,
    private cmvService: CMVService
  ) { }

  modalRef: MDBModalRef;

  displayedColumns1: string[] = ['product', 'balance', 'entry'];
  displayedColumnsDays: string[] = ['SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB', 'DOM', 'ADJUSTMENT']
  displayedColumns3: string[] = ['consumption', 'balance']
  displayedColumns4: string[] = ['title']
  displayedColumns6: string[] = ['consumption']

  year_list = []
  filter_year;
  filter_year_aux;

  month_list = []
  filter_month;
  filter_month_aux;

  week_list = []
  filter_week;
  filter_week_aux;
  week_number: number = 1;

  group_list = []
  filter_group;
  filter_group_aux;

  cost_centers = [{ description: "Todos", establishment: 4, id: "" }]
  filter_center: any = 1;

  date_from;
  date_to;

  establishment_id;

  product_list = []; // used as dataSource in tables 1, 2 and 3
  original_list = [];
  search: string = "";

  dataSource4 = [
    { title: 'Valor de consumo Total Grupos CMV (R$)', id: 't4l1' },
    { title: 'CMV de Consumo Diário', id: 't4l4' },
    { title: `Valor de consumo geral de (R$)`, id: 't4l2' },
    { title: `Consumo de carnes (Kg)`, id: 't4l3' }
  ];

  day_column1 = '2020-10-10';
  day_column2 = '2020-10-11';
  day_column3 = '2020-10-12';
  day_column4 = '2020-10-13';
  day_column5 = '2020-10-14';
  day_column6 = '2020-10-15';
  day_column7 = '2020-10-16';
  day_adjustment = '2020-10-16';

  column1 = {};
  column2 = {};
  column3 = {};
  column4 = {};
  column5 = {};
  column6 = {};
  column7 = {};
  adjustment = {};
  total_consumption = {}

  general_discharges = {}
  group_discharges = {}
  general_discharge_total = 0;
  group_discharge_total = 0;

  adjustment_general_discharges = {}
  adjustment_group_discharges = {}
  adjustment_general_discharge_total = 0;
  adjustment_group_discharge_total = 0;

  loading_count: number = 4;

  soma_total_kg: number = 0;
  kg = {}
  adjustment_kg = {}

  is_readonly: boolean = true;
  total_adjustment: number = 0;

  list_daily_billing = { list: {}, total: 0 }
  total_porcent_daily_billing: number = 0;

  ngOnInit() {
    this.establishment_id = localStorage.getItem('ESTABLISHMENT');

    this.year_list = this.datesService.getYearsList()
    this.month_list = this.datesService.month_list
    this.week_list = this.datesService.week_list

    this.filter_month = this.month_list[this.datesService.getActualMonth()]
    this.filter_year = this.datesService.getActualYear()

    this.filter_week = this.datesService.getActualWeekNew()
    this.datesService.getActualWeekNew()
    
    this.week_number = parseInt(this.filter_week.slice(-1))

    this.filter_year_aux = this.filter_year;
    this.filter_month_aux = this.filter_month;
    this.filter_week_aux = this.filter_week;
    this.filter_group_aux = this.filter_group;

    
    // this.filter_month = this.month_list[4]
    // this.filter_week = this.week_list[1]
    // this.filter_center = this.cost_centers[0].id

    this._loadGroupList();
    this._loadCostCenters(1);

  }

  getRevenues() {
    this.cmvService.getRevenues(
      this.establishment_id, this.date_from, this.date_to,
      this.filter_center
    ).subscribe(data => {
      this.total_porcent_daily_billing = 0;
      this.list_daily_billing.total = 0;

      this.list_daily_billing = data.results.reduce(function (acc, item) {
        acc.list[item.date] = item.value
        acc.total += item.value
        return acc
      }, { list: {}, total: 0 })
    }, error => {
      if (window.navigator.onLine) {
        this.getRevenues()
      }
    })
  }

  private _loadCostCenters(page) {
    this.costCenterService.getCostCenters(page, this.establishment_id).subscribe(data => {
      this.cost_centers = this.cost_centers.concat(data.results)
      if (data.next) {
        this._loadCostCenters(page + 1)
      }
      else {
        //this.filter_center = this.cost_centers.filter(el => el.establishment == null)[0]?.id
      }
    })
  }

  detailStock() {
    this.modalRef = this.modalService.show(StockDetailComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        costCenters: this.cost_centers.filter(el => el.id != ""),
        groupList: this.group_list
      }
    });
    this.modalRef.content.action.subscribe((res: any) => {
      if (res.entries) {
        this.changeDate()
      }
    });
  }

  stockAdjustment() {
    this.modalRef = this.modalService.show(StockAdjustmentComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        costCenters: this.cost_centers.filter(el => el.id != ""),
        groupList: this.group_list,
        weeks: this.week_list,
        weeks_fixed: this.week_list,
      }
    });
    this.modalRef.content.action.subscribe((res: any) => {
      if (res.entries) {
        this.changeDate()
      }
    });
  }

  getDownloadLink() {
    return `${environment.path}core/product-discharge/csv/?establishment=${this.establishment_id}&date_from=${this.date_from}&date_to=${this.date_to}`
  }

  searchProducts() {
    if (this.original_list.length <= 0) {
      this.original_list = this.product_list;
    }

    var array = this.original_list;

    const filterItems = (text) => {
      return array.filter(el => el.product.name.toLowerCase().indexOf(text.toLowerCase()) > -1)
    };

    this.product_list = filterItems(this.search);

    this.product_list.forEach(week => {
      week.consumption_week_7 = Number(week.consumption_week_7)
    });

  }

  private _loadGroupList() {
    this.groupsService.getEstablishmentGroups(this.establishment_id).subscribe(data => {
      for (let index = 0; index < data.results.length; index++) {
        if (data.results[index].status == true) {
          this.group_list.push(data.results[index])
          if (this.group_list.length == 1){
            this.filter_group = data.results[index]
            this.filter_group_aux = data.results[index]
          }

        }
      }
      this.loading_count -= 1;
      this.changeDate()
    });
  }

  getFilter(){
    this.filter_year = this.filter_year_aux;
    this.filter_month = this.filter_month_aux;
    this.loading_count = 0
    this.changeDate();
    this.changeGroup(true)
  }

  changeDate2() {
    this.week_list = []

    this.filter_week_aux = "Semana 1"
    this.filter_week = this.filter_week_aux;

    let totalweeks = this.datesService.weeksOnMonth(this.filter_year_aux,this.filter_month_aux.number)
    for (let index = 0; index < totalweeks; index++) {
      this.week_list[index] = "Semana "+(index+1)
    }


  }

  changeDate() {
    if (this.filter_year != null && this.filter_week != null && this.filter_month != null && this.filter_group != null) {

      this.week_list = this.datesService.getWeeksOptions(this.filter_month, this.filter_year)
      this.week_list.splice(0, 1)

      if (!this.week_list.includes(this.filter_week)) {
        this.filter_week = this.week_list[0]
        this.changeDate()
        return
      }

      this.week_number = parseInt(this.filter_week.slice(-1))
      let days = this.datesService.changeDate(this.filter_week, this.filter_month, this.filter_year)

      this.day_column1 = days['day1']
      this.day_column2 = days['day2']
      this.day_column3 = days['day3']
      this.day_column4 = days['day4']
      this.day_column5 = days['day5']
      this.day_column6 = days['day6']
      this.day_column7 = days['day7']

      // this.day_adjustment = days['day7']  // --> D/S/T/Q/Q/S/S
      this.day_adjustment = days['day1']     // --> S/T/Q/Q/S/S/D



      let first_last = this.datesService.lastDayOfMonth('01', this.filter_year, this.filter_month)
      var day_month1 = first_last['first_day']
      var day_month2 = first_last['last_day']

      if (this.filter_week == 'Semana 1') { // first week
        this.date_from = day_month1;
        this.date_to = days['day7']
        this.changeGroup(true)
      }
      else if (this.filter_month.number != days['day7'].substring(5, 7)) { // last week
        this.date_from = days['day1']
        this.date_to = day_month2
        this.changeGroup(true)
      }
      else {
        this.date_from = days['day1']
        this.date_to = days['day7']
        this.changeGroup(true)
      }

      this.getRevenues()



    }
  }

  changeGroup(loading: boolean) {
    this.soma_total_kg = 0

    this.dataSource4[2].title = `Valor de consumo geral de ${this.filter_group?.group?.name} (R$)`

    if (this.filter_year == null || this.filter_week == null || this.filter_month == null) {
      return
    }
    else {
      this.loading_count = this.loading_count > 0 ? this.loading_count : (loading ? 3 : 0);
      if (loading) this.clearColumns()

      this.stockService.getProductDischarge(
        this.establishment_id, this.date_from, this.date_to,
        this.filter_group.group.id, this.filter_center, null
      ).subscribe(data => {
        this.product_list = data;

        this.original_list = data;
        this.loading_count -= 1;

        data.forEach((element, index) => {
          element.consumption_week_6 = Number(element.consumption_week_6)
          this.total_adjustment = this.total_adjustment + element.consumption_week_7;

          this._setColumnsInitialQuantity(element.product.id)

          element.discharges.forEach(discharge => {
            this._setColumn(discharge.date, element.product.id, discharge.quantity, discharge.is_adjustment,this.filter_group.group.id,element.product.id,discharge.cost_center)
          });
        });

        this.getRevenues()
        this.getGeneralDischargeNew()
        this.getProductDischargeNew()
      }, error => {
        if (window.navigator.onLine) {
          this.changeGroup(loading)
        }
      })
    }
  }

  private _setColumnsInitialQuantity(col_id) {
    this.column1[col_id] = { quantity: 0, id: null, is_adjustment: false }
    this.column2[col_id] = { quantity: 0, id: null, is_adjustment: false }
    this.column3[col_id] = { quantity: 0, id: null, is_adjustment: false }
    this.column4[col_id] = { quantity: 0, id: null, is_adjustment: false }
    this.column5[col_id] = { quantity: 0, id: null, is_adjustment: false }
    this.column6[col_id] = { quantity: 0, id: null, is_adjustment: false }
    this.column7[col_id] = { quantity: 0, id: null, is_adjustment: false }
    this.adjustment[col_id] = { quantity: 0, id: null, is_adjustment: true }
    this.total_consumption[col_id] = { quantity: 0 }
  }

  private _setColumn(date, col_id, quantity, is_adjustment,group_id, product_id,cost_center) {

    let test_cost_center = false;
    if(cost_center == this.filter_center || this.filter_center == ""){
      test_cost_center = true;
    }



    if (date == this.day_column1 && is_adjustment == false && test_cost_center) {
      this._setColumnQuantity(this.column1, col_id, quantity)
    }
    else if (date == this.day_column2 && is_adjustment == false && test_cost_center) {
      this._setColumnQuantity(this.column2, col_id, quantity)
    }
    else if (date == this.day_column3 && is_adjustment == false && test_cost_center) {
      this._setColumnQuantity(this.column3, col_id, quantity)
    }
    else if (date == this.day_column4 && is_adjustment == false && test_cost_center) {
      this._setColumnQuantity(this.column4, col_id, quantity)
    }
    else if (date == this.day_column5 && is_adjustment == false && test_cost_center) {
      this._setColumnQuantity(this.column5, col_id, quantity)
    }
    else if (date == this.day_column6 && is_adjustment == false && test_cost_center) {
      this._setColumnQuantity(this.column6, col_id, quantity)
    }
    else if (date == this.day_column7 && is_adjustment == false && test_cost_center) {
      this._setColumnQuantity(this.column7, col_id, quantity)
    }

    let day = new Date(date).getUTCDate();

    let dayWeek = new Date(date).getUTCDay();

    if(is_adjustment == true && test_cost_center){
      this._setColumnQuantity(this.adjustment, col_id, quantity);
    }

  }

  private _setColumnQuantity(column, id, quantity) {
    column[id].quantity += quantity
    this.total_consumption[id].quantity += quantity
  }


  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }



  getGeneralDischargeNew() {
    this.stockService.getGeneralDischarge(
      this.establishment_id, this.date_from, this.date_to,
      this.filter_group.group.id, this.filter_center
    ).subscribe(data => {
      let general = this._getGeneralDischargesTotalAndObject(data.general_discharges)

      this.general_discharges = general.object
      this.general_discharge_total = general.total

      let group = this._getGeneralDischargesTotalAndObject(data.group_discharges)
      this.group_discharges = group.object
      this.group_discharge_total = group.total

      let general_adj = this._getGeneralDischargesTotalAndObject(data.general_discharges_adjustments)
      this.adjustment_general_discharges = general_adj.object
      this.adjustment_general_discharge_total = general_adj.total

      let group_adj = this._getGeneralDischargesTotalAndObject(data.group_discharges_adjustments)
      this.adjustment_group_discharges = group_adj.object
      this.adjustment_group_discharge_total = group_adj.total

      this.loading_count -= 1;
    }, error => {
      if (window.navigator.onLine) {
        this.getGeneralDischargeNew()
      }
    })
  }



  getProductDischargeNew() {
    //get kg count
    this.stockService.getProductDischarge(
      this.establishment_id, this.date_from, this.date_to,
      environment.carneId, this.filter_center, null
    ).subscribe(data => {

      this.kg = {}
      this.adjustment_kg = {}
      this.soma_total_kg = 0;

      this.loading_count -= 1;
      data.forEach(element => {
        element.discharges.forEach(discharge => {
          let date = discharge.date
          let quantity = discharge.quantity
          let is_adjustment = discharge.is_adjustment

          if (date == this.day_column1 && !is_adjustment) this.setKg(this.day_column1, quantity, is_adjustment)
          else if (date == this.day_column2  && !is_adjustment) this.setKg(this.day_column2, quantity, is_adjustment)
          else if (date == this.day_column3  && !is_adjustment) this.setKg(this.day_column3, quantity, is_adjustment)
          else if (date == this.day_column4  && !is_adjustment) this.setKg(this.day_column4, quantity, is_adjustment)
          else if (date == this.day_column5  && !is_adjustment) this.setKg(this.day_column5, quantity, is_adjustment)
          else if (date == this.day_column6  && !is_adjustment) this.setKg(this.day_column6, quantity, is_adjustment)
          else if (date == this.day_column7  && !is_adjustment) this.setKg(this.day_column7, quantity, is_adjustment)


          /*
          else if (date == this.day_column6 && !is_adjustment)
            this.setKg(this.day_column6, quantity, is_adjustment)
          else if (date == this.day_adjustment && is_adjustment)
            this.setKg(this.day_adjustment, quantity, is_adjustment)
          */

          if (is_adjustment){
            this.setKg(this.day_adjustment, quantity, is_adjustment)
          }


        });
      });
    }, error => {
      if (window.navigator.onLine) {
        this.getProductDischargeNew()
      }
    })
  }

  setKg(day, quantity, is_adjustment) {
    if (!is_adjustment) {
      let aux = this.kg[day] == null ? 0 : this.kg[day]
      this.kg[day] = this.round(aux + quantity)

      if (quantity != null) {
        this.soma_total_kg = this.round(this.soma_total_kg + quantity)
      }
    }
    else {
      let aux = this.adjustment_kg[day] == null ? 0 : this.adjustment_kg[day]
      this.adjustment_kg[day] = this.round(aux + quantity)

      if (quantity != null) {
        this.soma_total_kg = this.round(this.soma_total_kg + quantity)
      }
    }
  }

  clearColumns() {
    this.column1 = {}
    this.column2 = {}
    this.column3 = {}
    this.column4 = {}
    this.column5 = {}
    this.column6 = {}
    this.column7 = {}
    this.adjustment = {}
    this.kg = {}
    this.adjustment_kg = {}
    this.soma_total_kg = 0
  }

  private _getGeneralDischargesTotalAndObject(discharges) {
    return discharges.reduce(function (acc, item) {
      let value = item.total_quantity == null ? 0 : item.total_quantity
      acc.object[item.date] = value
      acc.total += value
      return acc
    }, { object: {}, total: 0 })
  }

  round(n) {
    return Math.round((n + Number.EPSILON) * 100) / 100
  }

  convertMoney(money) {
    if (money == null || money == "") money = 0

    var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(money);
  }

  porcentDaily(value, index, date) {
    if (index == 0) {
      this.total_porcent_daily_billing = 0
    }

    let number1 = (value != null && value != '') ? value : 0;

    let revenue = this.list_daily_billing.list[date]
    let number2 = revenue != null ? revenue : 0;

    if (number1 == 0 || number2 == 0) {
      return '0%'
    }
    else if (number1 > 0 || number2 > 0) {
      let total = number1 / number2;

      this.total_porcent_daily_billing += total;

      this.total_porcent_daily_billing = Number(this.total_porcent_daily_billing.toFixed(2));
      return `${(total * 100).toFixed(1)}%`
    }
    else return '0%'
  }

  porcentDaily2(value) {
    if (value == null || value == "") value = 0
    /*
    if (index == 0) {
      this.total_porcent_daily_billing = 0
    }

    let number1 = (value != null && value != '') ? value : 0;

    let revenue = this.list_daily_billing.list[date]
    let number2 = revenue != null ? revenue : 0;

    if (number1 == 0 || number2 == 0) {
      return '0%'
    }
    else if (number1 > 0 || number2 > 0) {
      let total = number1 / number2;

      this.total_porcent_daily_billing += total;

      this.total_porcent_daily_billing = Number(this.total_porcent_daily_billing.toFixed(2));
      return `${(total * 100).toFixed(1)}%`
    }
    else return '0%'
    */
  }

  toFixedNumber(number1, number2) {
    let total = 0;

    if (number1 == 0 || number2 == 0) {
      return 0
    }
    else {
      total = (number1 / number2) * 100
      return (total > 0 ? total : 0).toFixed(2)
    }
  }

  getHeight(i) {
      try {
        let size = document.getElementById(`row_${i}`).clientHeight
        let new_size = size < 48 ? 47.99 :                // 1 line
                      (size < 49 ? 48.82 :                // 2 line
                      (size < 73 ? 72.70 :                // 3 line
                      (size < 97 ? 96.53 :                // 4 line
                      (size < 121 ? 120.38 : size))))     // 5 line
        return new_size
      } catch {
        return 48
      }
  }

  openPopup() {
    this.modalRef = this.modalService.show(PopupDownloadComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        title: 'Download dos relatórios',
        screen: 'low stock',
        establishment: this.establishment_id,
        group_list: this.group_list,
      }
    });
  }

}
