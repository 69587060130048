import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ChartOptions, ChartType, ChartDataSets, Chart } from 'chart.js';
import { Label, Color, BaseChartDirective } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { GraphicsService } from '../../service/graphics.service';
import { StorageService } from '../../service/storage.service';
import { DatesService } from '../../service/dates.service';
import { GoalsService } from '../../service/goals.service';
import { GroupsService } from '../../service/groups.service';
import { InvoiceGoalSet } from '../../model/goal';
import { CMVService } from '../../service/cmv.service';

@Component({
  selector: 'graphics-shopping',
  templateUrl: './graphics-shopping.component.html',
  styleUrls: ['./graphics-shopping.component.css']
})

export class GraphicsShoppingComponent {

  //@ViewChild('chart2', { static: true }) chart2: BaseChartDirective;
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

  constructor(
    public graphicsService: GraphicsService,
    public storageService: StorageService,
    private datesService: DatesService,
    private goalsService: GoalsService,
    private groupsService: GroupsService,
    private cmvService: CMVService
  ) { }

  overall_percentage;
  loading_count = 2;
  loading_goal: boolean = false;
  has_goals: boolean = false;

  establishment_id;
  month_profit: number = 1;
  month_weeks: number = 5;

  cmv_group_goal = {}
  other_group_goal = {}

  week_dates = {}
  revenues = {}

  entries = {}
  projection_group = { 1: {}, 2: {}, 3: {}, 4: {}, 5: {} }
  projection_group_id = []

  all_groups = []

  week_projection = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
  miss_for_goal = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }

  timer;

  year_list = [];
  filter_year;

  month_list = [];
  filter_month;

  week_list = [];
  filter_week;

  day_week1 = '2020-10-10';
  day_week7 = '2020-10-16';
  day_month1;
  day_month2;

  heightGrafic2;

  total_soma: number = 0;
  goal_soma: number = 0;

  /* TABLES */
  displayedColumns: string[] = ['groups', 'meta', 'real', 'difference']
  dataSource = new MatTableDataSource<any>();

  /* GRAPHIC 1 */
  barChartData1: ChartDataSets[] = [
    { data: [10], label: 'Meta' },
    { data: [10], label: '', hidden: true },
    { data: [10], label: 'Compras' },
    { data: [10], label: '', hidden: true },
  ];
  biggest1 = Math.max.apply(Math, this.barChartData1[0].data);
  barChartOptions1: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: { beginAtZero: true, max: this.biggest1 + (this.biggest1 * 0.30) },
        display: false,
      }],
      xAxes: [{ display: false }],
    },
    tooltips: {
      callbacks: {
        label: function (context) {
          var label = '';
          if (context.datasetIndex == 0) {
            label = "Meta: "
          }
          else {
            label = "Compras: "
          }
          if (context.yLabel !== null) {
            var num = parseFloat(context.yLabel.toString())
            label += new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(num);
          }
          return label;
        }
      }
    },
    plugins: {
      minBarLength: 200,
      datalabels: {
        anchor: 'end',
        align: 'end',
        textAlign: "center",
        labels: {
          percentage: {
            font: {
              weight: 'bold',
              size: 15,
            },
            color: '#EF8A45',
            formatter: function (value, context) {
              return ` \n`
              // return `${value}% \n`
            }
          },
          value: {
            font: {
              size: 8
            },
            formatter: function (value, context) {
              var formatter = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              });
              let index = context.dataset.label == 'Compras' ? 3 : 1
              let aux: any = context.chart.data.datasets[index].data[context.dataIndex]
              //return ''
              return formatter.format(aux)
              //return `R$ ${value}`
              // == R$ [value from barChartData]
              // to show the LABEL from barChartLabels -> return context.chart.data.labels[context.dataIndex];
            }
          }
        },
        font: { family: 'Lato, sans serif' }
      }
    },
  };
  barChartLabels1: Label[] = [''];
  barChartType1: ChartType = 'bar';
  barChartLegend1 = false;
  barChartPlugins1 = [pluginDataLabels, showZeroPlugin];
  graphicColor1: Color[] = [{ backgroundColor: '#fff597' }, { backgroundColor: '#fff597' }, { backgroundColor: '#D4D5EA' }, { backgroundColor: '#D4D5EA' },]

  /* GRAPHIC 2 */
  barChartData2: ChartDataSets[] = [
    { data: [200], label: 'Meta' },
    { data: [200], label: 'Real' },
  ];
  biggest2A = Math.max.apply(Math, this.barChartData2[0].data) + 15;
  biggest2B = Math.max.apply(Math, this.barChartData2[1].data) + 15;
  barChartOptions2: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: { fontSize: 15, beginAtZero: true, max: this.biggest1 + (this.biggest1 * 0.30) },
        gridLines: { color: 'transparent' },
        display: true,

      }],
      xAxes: [{
        display: false,
        ticks: { /*
					max: (this.biggest2A >= this.biggest2B ?
						this.biggest2A + (this.biggest2A * 0.10) : this.biggest2B + (this.biggest2B * 0.10)
					),*/
        }
      }],
    },
    tooltips: {
      callbacks: {
        label: function (context) {
          var label = '';
          if (context.datasetIndex == 0) {
            label = "Meta: "
          }
          else {
            label = "Real: "
          }
          if (context.xLabel !== null) {
            var num = parseFloat(context.xLabel.toString())
            label += new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(num);
          }
          return label;
        }
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        labels: {
          percentage: {
            font: {
              size: 10,
              lineHeight: 1.2,
              family: 'Lato, sans serif'
            },
            formatter: function (value, context) {
              var formatter = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              });

              let array = context.chart.data.datasets[context.datasetIndex].data as number[]
              var sum = array.reduce(function (a, b) {
                return a + b;
              }, 0);
              let aux: any = sum == 0 || value == 0 ? 0 : (parseFloat(value) * 100) / parseFloat(sum.toString())

              return `${aux.toFixed(0)}% | R$ ${formatter.format(value)}`;
            }
          },
        }
      }
    },
  };
  barChartLabels2: Label[] = [''];
  barChartType2: ChartType = 'horizontalBar';
  barChartLegend2 = false;
  barChartPlugins2 = [pluginDataLabels, showZeroPlugin];
  graphicColor2: Color[] = [{ backgroundColor: '#F6C19C' }, { backgroundColor: '#B1B1B1' }]

  /* OTHERS */
  isWeek: boolean = false;

  graphic1_value = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

  /* FUNCTIONS  */

  ngOnInit() {
    this.year_list = this.datesService.getYearsList();
    this.month_list = this.datesService.month_list;
    this.week_list = this.datesService.week_list;

    this.filter_month = this.month_list[this.datesService.getActualMonth()]
    this.filter_year = this.datesService.getActualYear()
    this.filter_week = this.datesService.getActualWeekNew()

    this.loadGroups()

    this.establishment_id = localStorage.getItem('ESTABLISHMENT');
    if (localStorage.getItem('ESTABLISHMENT_GOAL') == "true") this.has_goals = true;
  }

  updateCharts() {
    this.charts.forEach((child, index) => {

      if (index == 0) {
        let biggest1A = Math.max.apply(Math, this.barChartData1[0].data);
        let biggest1B = Math.max.apply(Math, this.barChartData1[2].data);

        child.chart.options.scales.yAxes[0].ticks.max = (biggest1A >= biggest1B ?
          (biggest1A * 1.30) + 1 : (biggest1B * 1.30) + 1
        ),
          child.chart.update()
      }
      else if (index == 1) {
        let biggest2A = Math.max.apply(Math, this.barChartData2[0].data) + 15;
        let biggest2B = Math.max.apply(Math, this.barChartData2[1].data) + 15;

        child.chart.options.scales.xAxes[0].ticks.max = (biggest2A >= biggest2B ?
          biggest2A + (biggest2A * 0.30) + 1 : biggest2B + (biggest2B * 0.30) + 1
        ),
          child.chart.update()
      }

    });
  }

  changeDate() {
    if (this.filter_year != null && this.filter_week != null && this.filter_month != null) {
      let days = this.datesService.changeDate(this.filter_week, this.filter_month, this.filter_year)
      this.day_week1 = days['day1']
      this.day_week7 = days['day7']

      let first_last = this.datesService.lastDayOfMonth('01', this.filter_year, this.filter_month)
      this.day_month1 = first_last['first_day']
      this.day_month2 = first_last['last_day']

      this.loading_count = this.loading_count > 0 ? this.loading_count : 2;
      this.getGraphics()
      this.changeDate2()
    }
  }

  getGraphics() {
    var id_establishment = this.storageService.getCurrentEstablishment();

    this.graphicsService.getGraphicsTable(id_establishment, this.day_month1, this.day_month2, false).subscribe(data => {

      this.goalsService.getMonthlyEarningProjection(id_establishment, this.filter_month.num,
        this.filter_year).subscribe(data2 => {

          let group_goal = null
          let weeks = 5
          if (data2.results.length > 0) {
            weeks = data2.results[0].number_of_weeks
            group_goal = data2.results[0].invoicegoal_set[0].groupgoal_set
            if (data2.results[0].invoicegoal_set[1] != null)
              group_goal = group_goal.concat(data2.results[0].invoicegoal_set[1].groupgoal_set)
          }

          this.overall_percentage = (100 / data.length);
          this.overall_percentage = this.overall_percentage.toString() + '%';

          for (let index = 0; index < data.length; index++) {
            let total_value = this.round(data[index].total)
            // this.barChartData1[2].data[index] = data[index].percent;
            this.barChartData1[2].data[index] = total_value;
            this.barChartData1[3].data[index] = total_value;
            this.barChartLabels1[index] = data[index].group.name
            this.graphic1_value[index] = total_value

            let goal = null
            if (group_goal != null) {
              let goals = group_goal.filter(x => x.group == data[index].group.id)

              if (goals.length > 0) {
                goal = goals[0];
              }
            }

            goal = goal == null ? { percent: 0, value: 0 } : goal
            // this.barChartData1[0].data[index] = goal.percent * 100
            this.barChartData1[0].data[index] = goal.value;
            this.barChartData1[1].data[index] = goal.value;
          }

          this.heightGrafic2 = (48 * (data.length + 1)) - 16;

          this.loading_count -= 1;

          this.getTable(group_goal)
        })
    },
      (error) => {

      })
  }

  loadGroups() {
    var id_establishment = this.storageService.getCurrentEstablishment();

    this.groupsService.getEstablishmentGroups(id_establishment).subscribe(
      (data) => {
        let cmv = []
        let others = []

        data.results.forEach(element => {
          if (element.cmv && element.status) cmv.push(element)
          else if (element.status) others.push(element)
        });
        this.all_groups = cmv.concat(others)

        this.changeDate()
        this.changeDate2()
      },
      (err) => {
        console.error(err);
      }
    );
  }

  changeDate2() {
    var id_establishment = this.storageService.getCurrentEstablishment();

    this.goalsService.getMonthlyEarningProjection(id_establishment, this.filter_month.num,
      this.filter_year).subscribe(data => {

        this.month_profit = 0
        this.cmv_group_goal = {}
        this.other_group_goal = {}
        this.week_dates = {}
        this.revenues = {}
        this.entries = {}
        this.week_projection = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
        this.miss_for_goal = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }

        if (data.count != 0) {
          let item = data.results[0]
          this.setProjectionData(item)
        }
        else {
          this.loading_goal = true
          this.dataSource.data.forEach(el => {
            el.goal = 0
          })
        }

      })
  }

  setProjectionData(item) {
    this.month_profit = item.value;
    this.month_weeks = item.number_of_weeks;

    if (item.invoicegoal_set != null && item.invoicegoal_set.length > 0) {
      item.invoicegoal_set.forEach(element => {
        if (element.goal_type == 'CMV') {

          if (element.groupgoal_set != null && element.groupgoal_set.length > 0) {
            element.groupgoal_set.forEach(element2 => {
              this.cmv_group_goal[element2.group] = element2;
            });
          }

        }
        else if (element.goal_type == 'OTHER') {

          if (element.groupgoal_set != null && element.groupgoal_set.length > 0) {
            element.groupgoal_set.forEach(element2 => {
              this.other_group_goal[element2.group] = element2;

            });
          }
        }
      });
    }

    this.getDatesForRevenue()
  }

  getDatesForRevenue() {
    let first_last = this.datesService.changeDate('', this.filter_month, this.filter_year)

    for (let i = 0; i < this.month_weeks; i++) {
      this.week_dates[i + 1] = this.datesService.changeDate(this.week_list[i], this.filter_month, this.filter_year)
      if (i == 0) {
        this.getRevenues(first_last.day1, this.week_dates[i + 1].day7, i + 1)
        this.getEntries(first_last.day1, this.week_dates[i + 1].day7, i + 1)
      }
      else if (i + 1 == this.month_weeks) {
        this.getRevenues(this.week_dates[i + 1].day1, first_last.day7, i + 1)
        this.getEntries(this.week_dates[i + 1].day1, first_last.day7, i + 1)
      }
      else {
        this.getRevenues(this.week_dates[i + 1].day1, this.week_dates[i + 1].day7, i + 1)
        this.getEntries(this.week_dates[i + 1].day1, this.week_dates[i + 1].day7, i + 1)
      }
    }
  }

  getRevenues(first_day, last_day, i) {
    this.cmvService.getRevenues(this.establishment_id, first_day, last_day, null).subscribe(data => {
      this.revenues[i] = 0

      data.results.forEach(element => {
        if (element.date.substring(5, 7) == this.filter_month.number) {
          this.revenues[i] += element.value
        }
      });
      this.calcProjection()
    })
  }

  getEntries(first_day, last_day, i) {
    this.goalsService.getInvoiceEntry(first_day, last_day, this.establishment_id).subscribe(data => {
      this.entries[i] = {}

      data.forEach(element => {
        if (this.entries[i][element.group.id] == null)
          this.entries[i][element.group.id] = element.value
        else
          this.entries[i][element.group.id] += element.value
      });

      this.calcProjection()

      if (i + 1 == this.month_weeks) {
        let count = 0;
        this.timer = setInterval(() => {
          if (count < 1) {
            count += 1
            this.groupProjection()
            clearInterval(this.timer);
          }

        }, 2000);
      }
    })
  }

  calcProjection() {
    this.week_projection[1] = this.month_profit / this.month_weeks;
    this.miss_for_goal[1] = this.month_profit - this.revenues[1]

    this.week_projection[2] = (this.month_profit - this.revenues[1]) / (this.month_weeks - 1)
    this.miss_for_goal[2] = this.miss_for_goal[1] - this.revenues[2]

    this.week_projection[3] =
      this.revenues[2] == 0 ? this.week_projection[2] :
        (this.miss_for_goal[2]) / (this.month_weeks - 2)
    this.miss_for_goal[3] = this.miss_for_goal[2] - this.revenues[3]

    this.week_projection[4] =
      this.revenues[3] == 0 ? this.week_projection[3] :
        (this.miss_for_goal[3]) / (this.month_weeks - 3)
    this.miss_for_goal[4] = this.miss_for_goal[3] - this.revenues[4]

    this.week_projection[5] =
      this.revenues[5] != null && this.revenues[4] == 0 ? this.week_projection[4] : this.miss_for_goal[4]
    this.miss_for_goal[5] = this.miss_for_goal[4] - this.revenues[5]
  }

  async groupProjection() {
    this.all_groups.forEach((element, index) => {
      let id = element.group.id

      // -------------------------------------------
      // --------->  CONTA METAS CONFERIR <---------
      // -------------------------------------------

      try {
        let total = element.cmv == true ? this.cmv_group_goal[id].value : this.other_group_goal[id].value
        let media = element.cmv == true ? this.cmv_group_goal[id].media : this.other_group_goal[id].media
        let sum = 0
        let subtotal = 0

        for (let i = 1; i < 6; i++) {

          if (i == 1) {
            subtotal = total - sum;
            this.projection_group[i][id] = media
          }
          else if (this.entries[i - 1][id] != null && this.entries[i - 1][id] > 0) {
            sum += this.entries[i - 1][id]
            subtotal = total - sum;

            if (this.entries[i - 1][id] != null)
              media = (subtotal) / (this.month_weeks - i + 1)

            this.projection_group[i][id] = media
          }
          else {
            if (this.entries[i - 1][id] != null)
              media = (subtotal) / (this.month_weeks - i + 1)

            this.projection_group[i][id] = media
          }
        }
        //this.totalGoalWeek = sum;

      }
      catch { }

    })

    this.selectWeek(this.dataSource.data)
    this.loading_goal = true;
  }

  getTable(group_goal) {
    var id_establishment = this.storageService.getCurrentEstablishment();
    this.dataSource.data = []
    this.total_soma = 0
    this.goal_soma = 0

    this.graphicsService.getGraphicsTable(id_establishment, this.day_week1, this.day_week7, true).subscribe(data => {

      for (let index = 0; index < data.length; index++) {
        this.barChartData2[1].data[index] = Math.round(data[index].total);
        this.total_soma += data[index].total;

        let goal = 0

        if (group_goal != null) {
          var goals = group_goal.filter(x => x.group == data[index].group.id)

          if (goals.length > 0) {
            goal = goals[0].media;
          }
        }

        this.barChartData2[0].data[index] = goal

        data[index].goal = goal
        this.goal_soma += goal
        this.barChartLabels2[index] = data[index].group.name
      }


      var array = {
        goal: this.goal_soma,
        group: { id: 0, name: "Total", default: true, created_at: "2020-10-01T19:41:55" },
        percent: 0,
        total: this.total_soma
      }

      data.push(array)
      this.dataSource.data = data

      this.selectWeek(this.dataSource.data)

      this.loading_count -= 1;
      this.updateCharts()
    },
      (error) => {

      })
  }

  selectWeek(data) {

    var week; //month_weeks - 1

    if (this.filter_week == 'Semana 1') { week = 1 }
    else if (this.filter_week == 'Semana 2') { week = 2 }
    else if (this.filter_week == 'Semana 3') { week = 3 }
    else if (this.filter_week == 'Semana 4') { week = 4 }
    else if (this.filter_week == 'Semana 5') { week = 5 }
    // else if(this.filter_week == 'Semana 6'){ week = 6 }
    else week = 5

    // AJUSTE

    this.projection_group_id = []
    for (var prop in this.projection_group) {
      let list = []

      for (var id in this.projection_group[prop]) {
        // list.push(this.projection_group[prop][id])
        list.push({ id: id, value: this.projection_group[prop][id] })
      }
      this.projection_group_id.push(list)
    }



    // ORIGINAL
    // for (var prop in this.projection_group) {
    //   list_ids.push(this.projection_group[prop])
    //   ids_group.push({list: []})
    // }



    // list_ids.forEach((id, idx) => {

    //   for (var prop in ids_group) {

    //     list_ids.push(this.projection_group[prop])
    //   }
    // });

    var total = [0, 0, 0]
    for (let index = 0; index < data.length; index++) {

      let value;
      let obj_filter = this.projection_group_id[week - 1].filter(el => el.id == data[index].group.id)

      if (obj_filter != null && obj_filter.length > 0) value = obj_filter[0].value


      if (week == 2) {
        this.dataSource.data[index].goal = (!isFinite(value) ? 0 : value)
        this.barChartData2[0].data[index] = !isFinite(value) ? 0 : value
      }
      else if (week == 3) {
        this.dataSource.data[index].goal = (!isFinite(value) ? 0 : value)
        this.barChartData2[0].data[index] = !isFinite(value) ? 0 : value
      }
      else if (week == 4) {
        this.dataSource.data[index].goal = (!isFinite(value) ? 0 : value)
        this.barChartData2[0].data[index] = !isFinite(value) ? 0 : value
      }
      else if (week == 5) {
        this.dataSource.data[index].goal = (!isFinite(value) ? 0 : value)
        this.barChartData2[0].data[index] = !isFinite(value) ? 0 : value
      }

      total[0] += this.dataSource.data[index].goal;
      // total[1] += this.dataSource.data[index].percent;
      // total[2] += this.dataSource.data[index].total;
    }

    if (week != 1) {

      if (this.dataSource?.data[data.length - 1]?.goal != null)
        this.dataSource.data[data.length - 1].goal = total[0]

      // this.dataSource.data[data.length-1].percent = total[1]
      // this.dataSource.data[data.length-1].total = total[2]
    }

    this.updateCharts()
  }

  round(n) {
    return Math.round((n + Number.EPSILON) * 100) / 100
  }

  convertMoney(money) {
    var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(money);
  }

}

var showZeroPlugin = {
  beforeRender: function (chartInstance) {
    var datasets = chartInstance.config.data.datasets;

    for (var i = 0; i < datasets.length; i++) {
      var meta = datasets[i]._meta;
      // It counts up every time you change something on the chart so
      // this is a way to get the info on whichever index it's at
      var metaData = meta[Object.keys(meta)[0]];
      var bars = metaData.data;

      for (var j = 0; j < bars.length; j++) {
        var model = bars[j]._model;

        if (metaData.type === "horizontalBar" && model.base === model.x) {
          model.x = model.base + 2;
        } else if (model.base === model.y) {
          model.y = model.base - 2;
        }
      }
    }

  }
};
