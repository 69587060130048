import { RevenueDetailComponent } from './revenue-detail/revenue-detail.component';
import { Component } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';
import { DatesService } from '../../service/dates.service';
import { CMVService } from '../../service/cmv.service';
import { StockService } from '../../service/stock.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CostCenterService } from '../../service/cost-center.service';
import { ModalMessageComponent } from '../../components/modals/modal-message/modal-message.component';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cmv-management',
  templateUrl: './cmv-management.component.html',
  styleUrls: ['./cmv-management.component.css']
})

export class CMVManagementComponent {

  constructor(
    private datesService: DatesService,
    private cmvService: CMVService,
    private stockService: StockService,
    private costCenterService: CostCenterService,
    private modalService: MDBModalService,
  ) { }

  modalRef: MDBModalRef;

  year_list = [];
  filter_year;
  filter_year_select;

  month_list = [];
  filter_month;
  filter_month_select;

  week_list = [];

  days = [];
  weeks = [];
  weeks2 = [];

  cost_centers = [{ description: "Todos", establishment: 4, id: "" }]
  filter_center: any = 1;
  filter_center_select: any = 1;

  establishment_id;
  is_readonly: boolean = false;
  is_readonly_revenue: boolean = true;

  first_day;
  last_day;

  revenues = {};
  total_kg = {};
  total_carnes = {};
  total_semana_kg = [0, 0, 0, 0, 0];
  weekRevenues;
  revenue_info: any = {};
  loading_revenues: number = 0;

  displayedColumns: string[] = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB']
  dataSource = [1]
  loading_count = 0;
  loading_kg = true;

  inputModelChanged: Subject<any> = new Subject<any>();
  inputModelChangeSubscription: Subscription

  /* graphics */
  graphicColor: Color[] = [{ backgroundColor: '#77B465' }, { backgroundColor: '#B1B1B1' }]
  graphicColor2: Color[] = [{ backgroundColor: '#CA4F50' }, { backgroundColor: '#B1B1B1' }]

  g1 = { loaded: false, biggest: 10 }
  g2 = { loaded: false, biggest: 10 }
  g3 = { loaded: false, biggest: 10 }
  g4 = { loaded: false, biggest: 10 }

  loading_graphic: boolean = false;

  /* graphic 1 */
  barChartData: ChartDataSets[] = [
    { data: [68], label: 'Real' },
    { data: [35], label: 'Meta' }
  ];

  /* graphic 2 */
  barChartData2: ChartDataSets[] = [
    { data: [75], label: 'Real' }
  ];

  /* graphic 3 */
  barChartData3: ChartDataSets[] = [
    { data: [50], label: 'Real' },
    { data: [57], label: 'Meta' }
  ];

  /* graphic 4 */
  barChartData4: ChartDataSets[] = [
    { data: [77], label: 'Real' }
  ];

  /* row 4 */
  days4 = ['SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB','DOM', 'Total']
  daysTotal = ['SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB','DOM', 'Média']
  displayedColumns4 = ['days', 'week1', 'week2', 'week3', 'week4', 'week5']
  dataSource4 = [0, 1, 2, 3, 4, 5, 6, 7]

  displayedColumns6 = ['one', 'two', 'three']
  dataSource6 = [
    { value1: '1000 kg', value2: '1000 kg', value3: '50,70%', },
  ]

  list_day = { firstDay: '', lastDay: '' }
  total_cmv: number = 0;
  popup_save: boolean = true;
  loading = false;

  ngOnInit() {
    this.loading_revenues = 2
    this.loading_count = 1;

    this.establishment_id = localStorage.getItem('ESTABLISHMENT');

    this.year_list = this.datesService.getYearsList()
    this.month_list = this.datesService.month_list
    this.week_list = this.datesService.week_list

    this.week_list.shift();

    this.filter_month = this.month_list[this.datesService.getActualMonth()]
    this.filter_month_select = this.filter_month;

    this.filter_year = this.datesService.getActualYear()
    this.filter_year_select = this.filter_year;

    // this._loadCostCenters(1)
    // this.changeDate()
    // this.setDayDischarge()

    this.inputModelChangeSubscription = this.inputModelChanged.pipe(
      // debounceTime(100),
      distinctUntilChanged()
    ).subscribe((object) => {
      // if ( this.popup_save && (
      //   object.item == '0.00' ||
      //   object.item == 0 ||
      //   object.item == '' ||
      //   object.item == null
      // )) {
      //   this.openMessage(object)
      // } else {
      //   this.saveInput(object.item, object.date, object.type)
      // }
    });

    this.startResquet()
  }

  startResquet(){
    this.loading = true;

    this._loadCostCenters(1)
    this.changeDate()
    this.loading_revenues = this.loading_revenues > 0 ? this.loading_revenues : 2;

    this.setDayDischarge()

    this.loadRevenue()
    setTimeout(() => {
      this.getRevenuesInfo()
      this.getTotalKg()
    }, 1000);
  }

  ngOnDestroy() {
    this.inputModelChangeSubscription.unsubscribe();
  }

  setDayDischarge() {
    this.loading_revenues += 1;

    var date = new Date(`${this.filter_year}/${this.filter_month.number}/01`);
    var day_first = new Date(date.getFullYear(), date.getMonth(), 1);
    var day_last = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.list_day.firstDay = `${this.filter_year}-${this.filter_month.number}-${day_first.getDate()}`
    this.list_day.lastDay = `${this.filter_year}-${this.filter_month.number}-${day_last.getDate()}`

    this.getGeneralDischargeNew()
  }

  getGeneralDischargeNew() {
    if (this.list_day.firstDay != null && this.list_day.firstDay != '' && this.list_day.lastDay != null && this.list_day.lastDay != '') {
      this.stockService.getGeneralDischarge(
        this.establishment_id, this.list_day.firstDay, this.list_day.lastDay,
        7, this.filter_center
      ).subscribe(data => {
        this.total_cmv = 0;

        data.general_discharges.forEach(discharges => {
          this.total_cmv += discharges.total_quantity
        });

        data.general_discharges_adjustments.forEach(adjustments => {
          this.total_cmv += adjustments.total_quantity
        });

        this.loading_count = 2;
        setTimeout(() => {
          let total_revenues = this.totalRevenues()
          if (total_revenues <= 0) {
            this.barChartData2[0].data[0] = 0
          }
          else {
            this.barChartData2[0].data[0] = this.round((this.total_cmv / total_revenues) * 100)
          }
          this.g2.biggest = this.barChartData2[0].data[0]
          this.g2.loaded = true;
          this.loading_revenues -= 1;

          this.loading_count = 0;
        }, 500);


      }, error => {
        if (window.navigator.onLine) {
          this.getGeneralDischargeNew()
        }
      })
    }
  }

  detailRevenue(){
    this.modalRef = this.modalService.show(RevenueDetailComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        costCenters: this.cost_centers.filter(el => el.id != ""),
      }
    });
    this.modalRef.content.action.subscribe((res: any) => {
      if(res.entries) {
        this.changeDate()
      }
    });
  }

  showValue(i:number,num:number,date:string) {
    if(parseInt(date)<=7){
      return this.sumTotalKg(num)
    }else{
      return ""
    }

  }

  changeDate() {
    this.filter_year = this.filter_year_select;
    this.filter_month = this.filter_month_select;
    this.filter_center = this.filter_center_select;

    if (this.filter_year != null && this.filter_month != null) {
      // this.loading_revenues = this.loading_revenues > 0 ? this.loading_revenues : 2;
      this.revenues = {}
      this.total_kg = {}

      for (let i = 0; i < this.week_list.length; i++) {
        let days = this.datesService.changeDate(this.week_list[i], this.filter_month, this.filter_year)
        this.weeks[i] = days;
        this.weeks2[i] = { 0: days.day1, 1: days.day2, 2: days.day3, 3: days.day4, 4: days.day5, 5: days.day6, 6: days.day7 };

        for (var key in days) {
          this.revenues[days[key]] = 0
          this.total_kg[days[key]] = 0
        }
      }


      if (this.filter_center == null || this.filter_center == "")
        this.is_readonly = true
      else
        this.is_readonly = false



      let first_last = this.datesService.lastDayOfMonth('01', this.filter_year, this.filter_month)
      this.first_day = first_last['first_day']
      this.last_day = first_last['last_day']

      // this.setDayDischarge()

      // this.loadRevenue()
      // setTimeout(() => {
      //   this.getRevenuesInfo()
      //   this.getTotalKg()
      // }, 1000);
    }
  }

  loadRevenue() {
    this.loading_revenues = this.loading_revenues > 0 ? this.loading_revenues : 2;

    this.cmvService.getRevenues(this.establishment_id, this.first_day, this.last_day, this.filter_center).subscribe(data => {

      data.results.forEach(element => {
        this.revenues[element.date] += element.value
      });
      this.loading_revenues -= 1;
    })
  }

  getTotalKg() {
    this.cmvService.getTotalKg(this.establishment_id, this.first_day, this.last_day, this.filter_center).subscribe(data => {
      data.results.forEach(element => {
        this.total_kg[element.date] += element.value
      });

      this.getKgCarnes()

    }, error => {
      console.error(error)
    })
  }

  getKgCarnes() {
    this.total_carnes = {}
    this.stockService.getProductDischarge(this.establishment_id, this.first_day, this.last_day,
      environment.carneId, this.filter_center, null).subscribe(data => {
        data.forEach(element => {
          element.discharges.forEach(discharge => {
            this.setKg(discharge.date, discharge.quantity)
          });
        });
        this.loading_kg = false; this.loading_kg = false;
      })
  }

  setKg(day, quantity) {
    let aux = this.total_carnes[day] == null ? 0 : this.total_carnes[day]
    this.total_carnes[day] = aux + quantity
  }

  save() {
    let aux = this.setDataToRequest(this.revenues)
    this.cmvService.postRevenues(aux).subscribe(data => {
    })

    let aux2 = this.setDataToRequest(this.total_kg)
    this.cmvService.postTotalKg(aux2).subscribe(data => {
    })
  }

  setDataToRequest(data) {
    let aux = []
    for (var key in data) {
      if (key.substring(5, 7) == this.filter_month.number) {
        aux.push({
          "establishment": this.establishment_id,
          "date": key,
          "value": data[key] == null || data[key] == "" ? 0 : data[key],
          "cost_center": this.filter_center != null && this.filter_center != "" ? this.filter_center : 1
        })
      }
    }
    return aux;
  }

  saveInput(item, date, type) {
    if (this.loading_graphic) {
      this.getGeneralDischargeNew()
      this.getRevenuesInfo()
    }

    let aux = [{
      "establishment": this.establishment_id,
      "date": date,
      "value": item == null || item == "" ? 0 : item,
      "cost_center": this.filter_center != null && this.filter_center != "" ? this.filter_center : 1
    }]

    if (type == 1) {
      this.cmvService.postRevenues(aux).subscribe(data => {
        //this.getRevenuesInfo()
      })
    }
    else if (type == 2) {
      this.cmvService.postTotalKg(aux).subscribe(data => {
      })
    }

  }

  getRevenuesInfo() {
    this.g1.loaded = false;
    this.g2.loaded = false;
    this.g3.loaded = false;
    this.g4.loaded = false;

    this.cmvService.getRevenuesInfo(this.establishment_id, this.first_day, this.last_day, this.filter_center).subscribe(data => {
      this.revenue_info = data;

      let total_revenues = this.totalRevenues()
      if (total_revenues <= 0) {
        this.barChartData[0].data[0] = 0
        this.barChartData3[0].data[0] = 0
        this.barChartData2[0].data[0] = 0
        this.barChartData4[0].data[0] = 0
      }
      else {
        this.barChartData[0].data[0] = this.round((data.invoice_cmv / total_revenues) * 100)
        this.barChartData3[0].data[0] = this.round((data.invoice_other / total_revenues) * 100)
        this.barChartData2[0].data[0] = this.round((this.total_cmv / total_revenues) * 100)
        this.barChartData4[0].data[0] = this.round((data.discharge_other / total_revenues) * 100)
      }

      this.barChartData[1].data[0] = this.round(data.goal_invoice_cmv);
      this.g1.biggest = Math.max(this.barChartData[0].data[0], (data.goal_invoice_cmv))
      this.g1.loaded = true;

      this.barChartData3[1].data[0] = this.round(data.goal_invoice_other);
      this.g3.biggest = Math.max(this.barChartData3[0].data[0], (data.goal_invoice_other))
      this.g3.loaded = true;

      this.g2.biggest = this.barChartData2[0].data[0]
      this.g2.loaded = true;

      this.g4.biggest = this.barChartData4[0].data[0]
      this.g4.loaded = true;

      this.loading_count -= 1;
      this.loading_revenues -= 1;
      this.loading_graphic = false;
    }, error => {
      console.error(error)
    })
  }

  totalRevenues() {
    return Object.keys(this.revenues).reduce((sum, key) => sum + parseFloat(this.revenues[key] || 0), 0);
  }

  sumTotalKg(v) {
    var total = 0
    total += +this.total_kg[this.weeks2[v][0]]
    total += +this.total_kg[this.weeks2[v][1]]
    total += +this.total_kg[this.weeks2[v][2]]
    total += +this.total_kg[this.weeks2[v][3]]
    total += +this.total_kg[this.weeks2[v][4]]
    total += +this.total_kg[this.weeks2[v][5]]
    total += +this.total_kg[this.weeks2[v][6]]

    return this.round(total);
  }

  calcKg(day) {
    if (this.total_carnes[day] != null && this.total_carnes[day] != '') {
      if (this.total_kg[day] == null || this.total_kg[day] == 0) return 0

      var consumo = +this.total_kg[day]
      var carnes = this.total_carnes[day] == null ? 1 : this.total_carnes[day]

      return this.round((100 * carnes) / consumo)
    }
    else return 0
  }

  totalCalcKg(num) {
    var total = 0

    total += +this.calcKg(this.weeks2[num][0])
    total += +this.calcKg(this.weeks2[num][1])
    total += +this.calcKg(this.weeks2[num][2])
    total += +this.calcKg(this.weeks2[num][3])
    total += +this.calcKg(this.weeks2[num][4])
    total += +this.calcKg(this.weeks2[num][5])
    total += +this.calcKg(this.weeks2[num][6])

    if (total == 0) return 0

    return this.round(total / 7)
  }

  totalKg() {
    var total = 0;
    total += +this.sumTotalKg(0)
    total += +this.sumTotalKg(1)
    total += +this.sumTotalKg(2)
    total += +this.sumTotalKg(3)
    total += +this.sumTotalKg(4)
    return this.round(total)
  }

  totalCarne() {
    return this.round(Object.keys(this.total_carnes).reduce((sum, key) => sum + parseFloat(this.total_carnes[key] || 0), 0))
  }

  convertMoney(money) {
    var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(money);
  }

  round(n) {
    return Math.round((n + Number.EPSILON) * 100) / 100
  }

  private _loadCostCenters(page) {
    this.costCenterService.getCostCenters(page, this.establishment_id).subscribe(data => {
      this.cost_centers = this.cost_centers.concat(data.results)
      if (data.next) {
        this._loadCostCenters(page + 1)
      }
      else {
        this.filter_center = this.cost_centers.filter(el => el.establishment == null)[0]?.id
      }
    })
  }

  openMessage(object) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        title: '',
        text: `Dia: ${object.date.slice(-2)} \n\nDeseja salvar com o valor zero?`,
        has_button: true,
        button_text: 'Salvar',
        button_cancel: 'Cancelar'
      }
    });
    this.modalRef.content.action.subscribe((res: any) => {
      this.popup_save = true;
      if (res == 'yes') {
        this.loading_graphic = true;
        this.saveInput(object.item, object.date, object.type)
      }
    });
  }
}
