import { Component, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { HostListener } from '@angular/core';
import { ConstantsService } from '../../service/constants.service';

const areas = "top,weare,features,clients,contact";

@Component({
  selector: 'homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})

export class HomepageComponent {

  constructor(private constantsService: ConstantsService) { }

  @ViewChildren(areas) sections: QueryList<ElementRef>;

  lastElement: HTMLElement;
  isLogin: boolean = false;

  /* slider top */
  slider_data = [
    {
      line1: "Ter total controle dos Custos",
      line2: "do seu restaurante Nunca",
      line3: "foi tão Importante!",
      text: "A retomada da operação pós Covid-19 é um desafio imenso e o Restaurant Shop \
            vai te ajudar a gerenciar o principal grupo de custos do seu restaurante – \
            AS COMPRAS de forma simples e estratégica!",
    },
    {
      line1: "O que é preciso ",
      line2: "fazer para ter um",
      line3: "Restaurante Lucrativo?",
      text: "Controlar os custos do seu restaurante é a base para a Lucratividade! Com o \
            Restaurant Shop você vai conhecer os custos diários do seu cardápio, aprender \
            a fazer compras estratégicas e controlar o seu estoque!",
    }
  ]

  array = [1, 2, 3];
  clients_list = [];

  /* contact */
  name: string = "";
  email: string = "";
  message: string = "";
  error_message: string = "";
  our_email: string = "";
  our_phone: string = "";

  /* FUNCTIONS */

  ngOnInit() {
    this.lastElement = document.getElementById('btn-top');

    this.constantsService.getConstants().subscribe(data => {
      this.our_email = data.config.email
      this.our_phone = data.config.phone
    })

    this.constantsService.getClients().subscribe(data => {
      this.clients_list = data.results;
    })
  }

  ngAfterViewInit() {
    //document.getElementsByClassName('navbar')[0].setAttribute('style', 'box-shadow: none; min-height: 9vh;')
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    const activeSection = this.sections.toArray().findIndex(section => this.isElementInViewport(section.nativeElement));

    var element_name = `btn-${areas.split(',')[activeSection]}`;
    var element = document.getElementById(element_name)

    if (this.lastElement != null && element != null && this.lastElement.id != element_name) {
      this.lastElement.style.backgroundColor = "transparent";
      element.style.backgroundColor = "rgb(152, 154, 204, 0.16)";
      this.lastElement = element
    }

  }

  isElementInViewport(el) {
    var rect = el.getBoundingClientRect();

    return (
      rect.bottom - 150 >= 0 &&
      rect.right >= 0 &&
      rect.top - 150 <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  async scrollTo(element: HTMLElement, btn) {

    if (this.isLogin) {
      this.changeViews('flex', false)
    }

    if (this.lastElement != null) {
      this.lastElement.style.backgroundColor = "transparent";
    }

    this.lastElement = document.getElementById(btn)
    this.lastElement.style.backgroundColor = "rgb(152, 154, 204, 0.16)";

    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    var yOffset = 0;

    if (window.innerWidth <= 500) {
      yOffset = -68;
    } else {
      yOffset = -79;
    }

    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    if (supportsNativeSmoothScroll == true) {
      window.scrollTo({ top: y, behavior: 'smooth' });
    } else {
      element.scrollIntoView();
    }
  }

  changeViews(normal, login) {

    this.isLogin = login;
    if (this.lastElement != null) {
      this.lastElement.style.backgroundColor = "transparent";
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    document.getElementById('top').style.display = normal;
    document.getElementById('weare').style.display = normal;
    document.getElementById('features').style.display = normal;
    document.getElementById('clients').style.display = normal;
    document.getElementById('contact').style.display = normal;

  }

  postContact() {
    this.error_message = "";

    if (this.name == null || this.name == "") {
      this.error_message = "Por favor, insira o nome"
    }
    else if (this.email == null || this.email == "") {
      this.error_message = "Por favor, insira o email"
    }
    else if (this.message == null || this.message == "") {
      this.error_message = "Por favor, insira a mensagem"
    }
    else {
      this.constantsService.contact(this.email, this.name, this.message).subscribe(data => {
        this.error_message = "Mensagem enviada!"
        this.name = "";
        this.email = "";
        this.message = "";
      }, error => {
        this.error_message = "Erro ao enviar mensagem, por favor verifique as informações inseridas"
      })
    }

  }

  /* SLIDER CONFIGS */

  slideConfigTop = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": true,
    "arrows": false,
    "infinite": true,
    "autoplay": false,
    "autoplaySpeed": 2000,
    "responsive":
      [{
        "breakpoint": 600,
        "settings": {
          "dots": true,
          "autoplaySpeed": 2000,
        }
      }]
  };

  slideConfigClients = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "arrows": false,
    "infinite": true,
    "autoplay": false,
    "autoplaySpeed": 2000,
    "responsive":
      [{
        "breakpoint": 600,
        "settings": {
          "dots": false,
          "autoplaySpeed": 2000,
        }
      }]
  };

}
