import { Component, OnInit } from '@angular/core';
import { GroupsService } from '../../service/groups.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase-suggestion',
  templateUrl: './purchase-suggestion.component.html',
  styleUrls: ['./purchase-suggestion.component.css']
})
export class PurchaseSuggestionComponent implements OnInit {

  constructor(
    private groupsService: GroupsService,
  ) { }

  establishment_id;


  displayedColumns1: string[] = ['product'];
  displayedColumns2: string[] = ['balance'];
  displayedColumnsDays: string[] = ['week1', 'week2', 'week3', 'week4', 'week5', 'week6']
  displayedColumns3: string[] = ['consumption']

  product_list:any = []; // used as dataSource in tables 1, 2 and 3
  product_list_backup:any = []; // used as dataSource in tables 1, 2 and 3

  filter_group;

  group_list: any = [{
    cmv: true,
    created_at: '',
    establishment: 2,
    group: {
      default: true,
      id:-1,
      name: 'Todos',
      updated_at: '',
      created_at: '',
      status: true,
    }
  }
]

  loading_count: number = 0;
  search: string = "";
  group_id = 0
  safety_stock = 3

  loading = false

  numberes = [
    {num: 0, label: '0' },
    {num: 1, label: '1' },
    {num: 2, label: '2' },
    {num: 3, label: '3' },
    {num: 4, label: '4' },
    {num: 5, label: '5' },
    {num: 6, label: '6' },
    {num: 7, label: '7' },
    {num: 8, label: '8' },
    {num: 9, label: '9' },
    {num: 10, label: '10' },
  ]

  ngOnInit(): void {
    this.establishment_id = localStorage.getItem('ESTABLISHMENT');
    this._loadGroupList();
  }

  private _loadGroupList() {
    this.groupsService.getEstablishmentGroups(this.establishment_id).subscribe(data => {
      for (let index = 0; index < data.results.length; index++) {
        if(index==0){
          this.group_id = data.results[index]['group']['id']
          this.filter_group = data.results[index]
        }
        if (data.results[index].status == true) {
          this.group_list.push(data.results[index])
        }
      }

      this.getShoppingSuggestion();
    });




  }

  changeGroup(item: any) {
    this.group_id = item.group.id;
    this.getShoppingSuggestion();
  }

  getShoppingSuggestion() {
    this.loading = true
    this.product_list = []
    this.groupsService.getShoppingSuggestion(this.establishment_id, this.safety_stock, this.group_id).subscribe(data => {
      this.product_list = data
      this.product_list_backup = data
      this.loading = false
    });

  }


  getShoppingSuggestionDownload() {
    if (this.group_id && this.group_id > 0){
      return `${environment.path}core/shopping-suggestion/?establishment=${this.establishment_id}&safety_stock=${this.safety_stock}&group=${this.group_id}&donwload=true`
    }else{
      return `${environment.path}core/shopping-suggestion/?establishment=${this.establishment_id}&safety_stock=${this.safety_stock}&donwload=true`
    }
  }

  searchProducts(){
    this.product_list = this.product_list_backup.filter(el => el.product.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
  }

  openPopup(){
    // this.modalRef = this.modalService.show(PopupDownloadComponent, {
    //   backdrop: true,
    //   keyboard: true,
    //   focus: true,
    //   show: true,
    //   ignoreBackdropClick: false,
    //   class: 'modal-dialog-centered modal-dialog',
    //   containerClass: '',
    //   animated: false,
    //   data: {
    //     title: 'Download dos relatórios',
    //     screen: 'low stock',
    //     establishment: this.establishment_id,
    //     group_list: this.group_list,
    //   }
    // });
  }

  round(n) {
    return Math.round((n + Number.EPSILON) * 100) / 100
  }

  convertMoney(money) {
    if (money == null || money == "") money = 0

    var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(money);
  }

}
