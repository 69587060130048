import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { InvoiceGoalSet } from '../../model/goal';
import { DatesService } from '../../service/dates.service';
import { GoalsService } from '../../service/goals.service';
import { GroupsService } from '../../service/groups.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CMVService } from '../../service/cmv.service';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalErrorComponent } from '../../components/modals/modal-error/modal-error.component';

@Component({
  selector: 'goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.css']
})

export class GoalsComponent {

  constructor(
    private datesService: DatesService,
    private goalsService: GoalsService,
    private groupsService: GroupsService,
    private cmvService: CMVService,
    private router: Router,
    private modalService: MDBModalService
  ) { }

  modalRef: MDBModalRef;
  year_list = [];
  filter_year;
  month_list = [];
  filter_month;
  week_list = []

  establishment_id;
  projection_id: number;
  month_profit: number = 1;
  month_weeks: number = 5;

  cmv_goal: InvoiceGoalSet;
  other_goal: InvoiceGoalSet;
  cmv_goal_percent: number;
  other_goal_percent: number;

  cmv_group_goal_percent = {}
  other_group_goal_percent = {}
  cmv_group_goal = {}
  other_group_goal = {}

  goal_to_post: InvoiceGoalSet = {
    goal_type: '',
    groupgoal_set: [],
    monthly_earning_projection: null,
    id: null,
    percent: 0,
    value: 0
  }

  week_dates = {}
  revenues = {}
  total_revenues = 0

  entries = {}
  entries_group = {}
  projection_group = { 1: {}, 2: {}, 3: {}, 4: {}, 5: {} }
  total_entries = 0

  all_groups = []

  week_projection = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
  miss_for_goal = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }

  inputModelChanged: Subject<any> = new Subject<any>();
  inputModelChangeSubscription: Subscription

  displayedColumns1 = ['group', 'percent', 'value', 'average']
  dataSource1 = new MatTableDataSource<any>();

  displayedColumns2 = ['group', 'percent', 'value', 'average']
  dataSource2 = new MatTableDataSource<any>();

  displayedColumns3 = ['name', 'week1', 'week2', 'week3', 'week4', 'week5']
  dataSource3 = new MatTableDataSource<any>();

  displayedColumns4 = ['group', 'week1', 'week2', 'week3', 'week4', 'week5']
  dataSource4 = new MatTableDataSource<any>();

  displayedColumns5 = ['group', 'week1', 'week2', 'week3', 'week4', 'week5']
  dataSource5 = new MatTableDataSource<any>();

  timer;

  ngOnInit() {
    this.establishment_id = localStorage.getItem('ESTABLISHMENT');
    if (localStorage.getItem('ESTABLISHMENT_GOAL') != "true") this.router.navigate(['/main/graphics-shopping'])

    this.year_list = this.datesService.getYearsList()
    this.month_list = this.datesService.month_list

    this.filter_month = this.month_list[this.datesService.getActualMonth()]
    this.filter_year = this.datesService.getActualYear()

    this.week_list = this.datesService.week_list

    /* mocked stuff */
    this.dataSource3.data = ['PROJEÇÃO', 'REAL', 'FALTA P/ META']

    this.changeDate()
    this.loadGroups()

    this.inputModelChangeSubscription = this.inputModelChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((object) => {

      if (object.type == 'month_profit') {

        if (this.projection_id == null) {
          this.postProjection(false, "")
        }
        else {
          this.patchProjection() //valor faturamento mensal
        }

      }
      else if (object.type == 'goal') {		//title
        let goal_item = { type: object.type2, percent: object.percent }
        var somaPorcent = 0

        somaPorcent = parseFloat(this.other_goal_percent.toString()) + parseFloat(this.cmv_goal_percent.toString())

        if (this.projection_id == null) {
          this.postProjection(true, goal_item)
        }

        else if (object.obj.id == null) {

          if (somaPorcent > 100) {
            if (object.type2 == "CMV") {
              goal_item = { type: object.type2, percent: 0 }
            }
            else if (object.type2 == "OTHER") {
              goal_item = { type: object.type2, percent: 0 }
            }
            var text = 'O somatório da Meta CMV e Meta Outros superou 100%'
            this.openMessage(text)
          }

          this.postInvoiceGoal(this.projection_id, goal_item)
        }
        else {
          if (somaPorcent > 100) {
            if (object.type2 == "CMV") {
              object.percent = 0
            }
            else if (object.type2 == "OTHER") {
              object.percent = 0
            }
            var text = 'O somatório da Meta CMV e Meta Outros superou 100%'
            this.openMessage(text)
          }

          this.patchInvoiceGoal(object.obj.id, object.percent)
        }

      }
      else if (object.type == 'group') {	// table
        let goal_item = { type: object.type2, percent: object.percent }

        if (this.projection_id == null) {
          this.postProjection(true, goal_item)
        }
        else if (object.obj.id == null) {
          this.postInvoiceGoal(this.projection_id, goal_item)
        }
        else if (object.item == null) {
          var somaOther = 0;
          var somaCmv = 0;

          if (object.type2 == 'CMV') {
            for (var key in this.cmv_group_goal_percent) {
              this.cmv_group_goal_percent[key] = parseFloat(this.cmv_group_goal_percent[key] == null ? 0 : this.cmv_group_goal_percent[key])
              somaCmv += this.cmv_group_goal_percent[key]
            }
            if (somaCmv > 100) {
              object.value = 0
              var text = 'O somatório % dos Grupos para CMV superou 100%'
              this.openMessage(text)
            }
          }
          else {
            for (var key in this.other_group_goal_percent) {
              this.other_group_goal_percent[key] = parseFloat(this.other_group_goal_percent[key] == null ? 0 : this.other_group_goal_percent[key])
              somaOther += this.other_group_goal_percent[key]
            }
            if (somaOther > 100) {
              object.value = 0
              var text = 'O somatório % dos Grupos outros superou 100%'
              this.openMessage(text)
            }
          }

          this.postGroupGoal(object.group, object.value, object.obj.id)
        }
        else {
          var somaOther = 0;
          var somaCmv = 0;

          if (object.type2 == 'CMV') {
            for (var key in this.cmv_group_goal_percent) {
              this.cmv_group_goal_percent[key] = parseFloat(this.cmv_group_goal_percent[key] == null ? 0 : this.cmv_group_goal_percent[key])
              somaCmv += this.cmv_group_goal_percent[key]
            }
            if (somaCmv > 100) {
              object.value = 0
              var text = 'O somatório % dos Grupos para CMV superou 100%'
              this.openMessage(text)
            }
          }
          else {
            for (var key in this.other_group_goal_percent) {
              this.other_group_goal_percent[key] = parseFloat(this.other_group_goal_percent[key] == null ? 0 : this.other_group_goal_percent[key])
              somaOther += this.other_group_goal_percent[key]
            }
            if (somaOther > 100) {
              object.value = 0
              var text = 'O somatório % dos Grupos outros superou 100%'
              this.openMessage(text)
            }
          }
          this.patchGroupGoal(object.item.id, object.value)
        }
      }
    });
  }

  ngOnDestroy() {
    this.inputModelChangeSubscription.unsubscribe();
  }

  openMessage(text) {
    this.modalRef = this.modalService.show(ModalErrorComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        text: text,
      }
    });

  }

  loadGroups() {
    this.groupsService.getEstablishmentGroups(this.establishment_id).subscribe(
      (data) => {
        let cmv = []
        let others = []

        data.results.forEach(element => {
          if (element.cmv && element.status) cmv.push(element)
          else if (element.status) others.push(element)
        });

        let total = [{
          group: {name: 'Total'},
          id: null
        }]

        this.dataSource1.data = cmv.concat(total);
        this.dataSource2.data = others.concat(total);
        this.dataSource4.data = cmv;
        this.dataSource5.data = others;
        this.all_groups = cmv.concat(others)
      },
      (err) => {
        console.error(err);
      }
    );
  }

  changeDate() {
    this.goalsService.getMonthlyEarningProjection(this.establishment_id, this.filter_month.num,
      this.filter_year).subscribe(data => {

        this.month_profit = 0
        this.cmv_goal = this.goal_to_post;
        this.other_goal = this.goal_to_post;
        this.cmv_goal_percent = 0;
        this.other_goal_percent = 0;
        this.cmv_group_goal_percent = {}
        this.other_group_goal_percent = {}
        this.cmv_group_goal = {}
        this.other_group_goal = {}
        this.projection_id = null;
        this.week_dates = {}
        this.total_revenues = 0
        this.revenues = {}
        this.entries = {}
        this.entries_group = {}
        this.week_projection = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
        this.miss_for_goal = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }

        if (data.count != 0) {
          let item = data.results[0]
          this.setProjectionData(item)
        }
      })
  }

  setProjectionData(item) {
    this.month_profit = 0
    this.cmv_goal = this.goal_to_post;
    this.other_goal = this.goal_to_post;
    this.cmv_goal_percent = 0;
    this.other_goal_percent = 0;
    this.cmv_group_goal_percent = {}
    this.other_group_goal_percent = {}
    this.cmv_group_goal = {}
    this.other_group_goal = {}
    this.projection_id = null;
    this.week_dates = {}
    this.total_revenues = 0
    this.revenues = {}
    this.entries = {}
    this.entries_group = {}
    this.week_projection = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
    this.miss_for_goal = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }

    this.projection_id = item.id
    this.month_profit = item.value;
    this.month_weeks = item.number_of_weeks;

    if (item.invoicegoal_set != null && item.invoicegoal_set.length > 0) {
      item.invoicegoal_set.forEach(element => {
        if (element.goal_type == 'CMV') {
          this.cmv_goal = element;
          this.cmv_goal_percent = element.percent * 100;

          if (element.groupgoal_set != null && element.groupgoal_set.length > 0) {
            element.groupgoal_set.forEach(element2 => {
              this.cmv_group_goal_percent[element2.group] = element2.percent * 100;
              this.cmv_group_goal[element2.group] = element2;
            });
          }

        }
        else if (element.goal_type == 'OTHER') {
          this.other_goal = element;
          this.other_goal_percent = element.percent * 100;

          if (element.groupgoal_set != null && element.groupgoal_set.length > 0) {
            element.groupgoal_set.forEach(element2 => {
              this.other_group_goal_percent[element2.group] = element2.percent * 100;
              this.other_group_goal[element2.group] = element2;
            });
          }
        }
      });
    }

    this.getDatesForRevenue()
  }

  postProjection(do_goal, goal_item) {
    this.goalsService.postMonthlyEarningProjection(this.establishment_id, this.filter_month.num,
      this.filter_year, this.month_profit, this.month_weeks).subscribe(data => {
        if (do_goal) {
          this.postInvoiceGoal(data.id, goal_item)
        }
        else {
          this.setProjectionData(data)
        }
      })
  }

  patchProjection() {
    this.goalsService.patchMonthlyEarningProjection(this.projection_id, this.month_profit,
      this.month_weeks).subscribe(data => {
        this.setProjectionData(data)
      })
  }

  postInvoiceGoal(projection_id, item) {
    this.goalsService.postInvoiceGoal(item.type, item.percent / 100, projection_id).subscribe(data => {
      this.changeDate()
    })
  }

  patchInvoiceGoal(id, percent) {
    this.goalsService.patchInvoiceGoal(id, percent / 100).subscribe(data => {
      this.changeDate()
    })
  }

  postGroupGoal(group, percent, invoice_goal) {
    this.goalsService.postGroupGoal(group, percent / 100, invoice_goal).subscribe(data => {
      this.changeDate()
    })
  }

  patchGroupGoal(id, percent) {
    this.goalsService.patchGroupGoal(id, percent / 100).subscribe(data => {
      this.changeDate()
    })
  }

  getDatesForRevenue() {
    let first_last = this.datesService.changeDate('', this.filter_month, this.filter_year)

    for (let i = 0; i < this.month_weeks; i++) {
      this.week_dates[i + 1] = this.datesService.changeDate(this.week_list[i], this.filter_month, this.filter_year)
      if (i == 0) {
        this.getRevenues(first_last.day1, this.week_dates[i + 1].day7, i + 1)
        this.getEntries(first_last.day1, this.week_dates[i + 1].day7, i + 1)
      }
      else if (i + 1 == this.month_weeks) {
        this.getRevenues(this.week_dates[i + 1].day1, first_last.day7, i + 1)
        this.getEntries(this.week_dates[i + 1].day1, first_last.day7, i + 1)
      }
      else {
        this.getRevenues(this.week_dates[i + 1].day1, this.week_dates[i + 1].day7, i + 1)
        this.getEntries(this.week_dates[i + 1].day1, this.week_dates[i + 1].day7, i + 1)
      }
    }
  }

  getRevenues(first_day, last_day, i) {
    this.cmvService.getRevenues(this.establishment_id, first_day, last_day, null).subscribe(data => {
      this.revenues[i] = 0

      data.results.forEach(element => {
        if (element.date.substring(5, 7) == this.filter_month.number) {
          this.revenues[i] += element.value
          this.total_revenues += element.value
        }
      });
      this.calcProjection()
    })
  }

  getEntries(first_day, last_day, i) {
    this.goalsService.getInvoiceEntry(first_day, last_day, this.establishment_id).subscribe(data => {
      this.entries[i] = {}

      data.forEach(element => {
        if (this.entries[i][element.group.id] == null) this.entries[i][element.group.id] = element.value
        else this.entries[i][element.group.id] += element.value

        if (this.entries_group[element.group.id] == null) this.entries_group[element.group.id] = element.value
        else this.entries_group[element.group.id] += element.value

        this.total_entries += element.value
      });
      this.calcProjection()

      if (i + 1 == this.month_weeks) {
        let count = 0;
        this.timer = setInterval(() => {
          if (count < 1) {
            count += 1
            this.groupProjection()
            clearInterval(this.timer);
          }

        }, 2000);
      }
    })
  }

  convertMoney(money) {
    money = money == null ? 0 : money
    var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(money);
  }

  calcProjection() {
    this.week_projection[1] = this.month_profit / this.month_weeks;
    this.miss_for_goal[1] = this.month_profit - this.revenues[1]

    this.week_projection[2] = (this.month_profit - this.revenues[1]) / (this.month_weeks - 1)
    this.miss_for_goal[2] = this.miss_for_goal[1] - this.revenues[2]

    this.week_projection[3] =
      this.revenues[2] == 0 ? this.week_projection[2] :
        (this.miss_for_goal[2]) / (this.month_weeks - 2)
    this.miss_for_goal[3] = this.miss_for_goal[2] - this.revenues[3]

    this.week_projection[4] =
      this.revenues[3] == 0 ? this.week_projection[3] :
        (this.miss_for_goal[3]) / (this.month_weeks - 3)
    this.miss_for_goal[4] = this.miss_for_goal[3] - this.revenues[4]

    this.week_projection[5] =
      this.revenues[5] != null && this.revenues[4] == 0 ? this.week_projection[4] : this.miss_for_goal[4]
    this.miss_for_goal[5] = this.miss_for_goal[4] - this.revenues[5]
  }

  async groupProjection() {
    this.all_groups.forEach(element => {
      let id = element.group.id

      try {
        let total = element.cmv == true ? this.cmv_group_goal[id].value : this.other_group_goal[id].value
        let media = element.cmv == true ? this.cmv_group_goal[id].media : this.other_group_goal[id].media
        let sum = 0

        /*for (let i = 1; i < 6; i++) {
          if (this.entries[i][id] != null && this.entries[i][id] > 0) {
            this.projection_group[i][id] = this.entries[i][id]
            sum += this.entries[i][id]
            media = (total - sum) / (this.month_weeks - i)
          }
          else {
            this.projection_group[i][id] = media
          }
        }*/

        for (let i = 1; i < 6; i++) {
          if(i == 1){
            this.projection_group[i][id] = media
          }
          else if (this.entries[i-1][id] != null && this.entries[i-1][id] > 0) {
            sum += this.entries[i-1][id]
            media = (total - sum) / (this.month_weeks - i + 1)
            this.projection_group[i][id] = media
          }
          else {
            media = (total - sum) / (this.month_weeks - i + 1)
            this.projection_group[i][id] = media
          }
        }

      }
      catch { }

    })

  }

  sumColumn(values, key=null){
    let total = 0

    Object.values(values).forEach((el: any) => {
      if(key){
        total += el[key]
      }
      else{
        total += el
      }
    })

    return total.toString()
  }

}
